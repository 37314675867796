@media only screen and (max-width: 575px) {
  .tmc, .tmc h2 {
    text-align: center !important;
  }
}

.modal-img {
  width: 80px;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

.close {
  background: white;
}

.menu li {
  list-style: none;
}

.mt10 {
  margin-top: 10px;
}
.w100 {
  width: 100%;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.modal-body {
  font-size: 20px;
}

.succ {
  padding: 50px 0;
  font-size: 25px;
  text-align: center;
  animation: fadeIn .8s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.form {
  color: black;
  background: white;
  padding: 20px;
}

.s1-content a:hover {
  color: rgb(241, 255, 248);
}

.frame-wrap {
  max-width: 1000px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 20px;
}

.frame-container {
  width: 100%;
  padding-top: 57%; /* 1:1 Aspect Ratio */
  position: relative;
}

.for-frame-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  //height: 600px;
  //max-height: 75vh;
  iframe {
    height: 100%;
    width: 100%;
  }
}

.form .name {
  font-weight: 400;
}

.form b {
  font-size: 25px;
  display: inline-block;
  margin-bottom: 5px;
}

.sale {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 50%;
  color: white;
  width: 75px;
  height: 75px;
  font-weight: bold;
  background: #22c55e;
  text-align: center;
  font-size: 25px;
  line-height: 75px;
  box-shadow: 0 0 14px 8px #c7c7c7;
  animation: boxShadow 1.5s infinite;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left;
}

.vt {
  vertical-align: top;
}
@keyframes boxShadow {
  0% {
    box-shadow: 0 0 15px 8px #c7c7c7;
  }
  50% {
    box-shadow: 0 0 25px 8px #c7c7c7;
  }
  100% {
    box-shadow: 0 0 15px 8px #c7c7c7;
  }

}

#form1 input {
  font-size: 20px;
}

#form1 input, #form1 textarea {
  margin-top: 10px;
}

#form1 {
  margin-bottom: 30px;
  margin-top: 40px;
  width: 100% !important;
  max-width: 100% !important;
}

.auto {
  margin: 0 auto;
  text-align: center;
}

.btn.btn1:hover {
  color: white !important;
}

#form2 {
  //width: 100%;
  max-width: 800px !important;
  margin: 0 auto;
}

body, * {
  scroll-behavior: unset;
}

//a:hover {
//  color: inherit!important;
//}

.sec1 {
  position: relative;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 650px;
  background: url(/imgs/main.png) 40% /cover no-repeat;
  color: #fff;
}

.sec1:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: .95;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.7));
}


.ffot, .ffot div, .ffot h2 {
  text-align: center;
}

.col22 {
  color: #22c55e;
}

.buttons {
  color: white;
}

.buttons a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.mw {
  padding: 20px;
  margin: 20px;
}

.buttons .fab {
  float: left;
  margin-top: 1px;
  margin-right: 10px;
}

.buttons > div {
  border-radius: 20px;
  padding: 7px 20px;
  margin: 3px 3px;
}

.buttons .fab {
  color: grey;
  font-size: 21px;
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'Circular-Loom';

  src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Book-cd7d2bcec649b1243839a15d5eb8f0a3.woff2') format('woff2');
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: 'Circular-Loom';

  src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Medium-d74eac43c78bd5852478998ce63dceb3.woff2') format('woff2');
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: 'Circular-Loom';

  src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Bold-83b8ceaf77f49c7cffa44107561909e4.woff2') format('woff2');
}

@font-face {
  font-weight: 900;
  font-style: normal;
  font-family: 'Circular-Loom';

  src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Black-bf067ecb8aa777ceb6df7d72226febca.woff2') format('woff2');
}

.logo_block img {
  height: 40px;
}

.logo_block {
  margin-top: 5px;
  color: black;
}

.logo_txt {
  float: right;
  display: inline-block;
  font-size: 13px;
  line-height: 13px;
  margin-left: 3px;
}

.mt-10 {
  padding-top: 30px;
}

.pb30 {
  padding-bottom: 30px;
}

.main-icons strong {
  color: #08a652;
}

.main-icons > div {
  width: 100%;
  display: block;
}

.main-icons {
  font-size: 17px;
}

.slnav {
  background: #ECF1F7;
  border-radius: 32px;
  color: #77a2cc;
  width: 32px;
  height: 32px;
  display: inline-block;
  padding: 0;
  margin-left: -60px;
}

.main-icons i {
  font-size: 15px !important;
  /*float: left;*/
  margin-top: 3px;
  margin-right: 5px;
}

.main-icons li {
  display: block;
}

a.ants {
  color: black;
  text-decoration: underline;
}

.ants {
  max-width: 140px;
  text-align: center;
  display: inline-block;
  padding: 5px;
  margin: 0 15px;
}
.ants img {
  height: 60px;
}

.antsss {
  a {
    color: black !important;
  }

  margin-top: 25px;
}

$ui-color: rgba(102, 102, 102, .6);

.uk-light .uk-dotnav > * > * {
  border-color: $ui-color;
}

.uk-light .uk-dotnav > .uk-active > * {
  background: $ui-color;
}

.uk-slider-nav {
  > li {
    > a {
      border-color: #d9d9d9;
    }

    display: inline-block;
  }

  margin-top: 20px !important;
  display: inline-block !important;
}


.mw {
  max-width: 1300px;
  margin: 0 auto;
  text-align: center;
}

.c4 {
  width: 25%;
  background: green;
}

.row {
  /*wdith: 100%;*/
  /*max-width: 1200px;*/
  /*background: red;*/
}

.main-data {
  margin-bottom: 20px;
}

.main-data img {
  width: 45px;
  /*height: 35px;*/
}

.main-data .col-sm-3 {
  margin-bottom: 20px;
}

.main-data .col-sm-3 div {
  margin-top: 10px;
  font-weight: 500;
}

.logoi img {
  max-height: 100%;
}

.logoi {
  height: 120px;
}


.imgs {
  text-align: center;
}

.imgs img {
  height: 82px;
  margin: 10px 8px;
}

.tc {
  text-align: center;
}

h2 {
  font-size: 2em;
}

.advantages-items h3 {
  padding-left: 0;
  margin-left: 0;
}

.advantages-item-img img {
  max-width: 100%;
  width: 60px;
}

.advantages-item-img {
  padding-right: 0;
  padding-top: 15px
}

.sep-green {
  max-width: 90%;
  width: 500px;
  height: 3px;
  background: #22c55e;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 30px;
}

.ccus button {
  background: white;
  margin: 10px 0;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 20px;
}

.ccus {
  display: block;
  background: #22c55e;
  color: white !important;
  position: relative;
  overflow: hidden;
  border-radius: 15px;

}

.ccus > div {
  padding: 20px;
  font-size: 27px;
  font-weight: 800;
}

.ccus img {
  position: absolute;
  right: 5%;
}


.feed {
  width: 200px;
  height: auto !important;
}

img {
  max-width: 100%;
}

.healing > div {
  margin-top: 20px;
}

.ti-review-content {
  line-height: 20.3px !important;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: inherit !important;
  overflow-x: inherit !important;
  overflow-y: inherit !important;
  -webkit-line-clamp: inherit !important;
  overflow: auto !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  padding-right: 10px;
  scrollbar-color: #8d8d8d #e0e0e0;
  scrollbar-width: thin
}

.ti-review-content .ti-stars {
  margin-bottom: 5px
}

.ti-review-content strong {
  font-size: inherit;
  color: inherit
}

.ti-review-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  position: relative
}

.ti-review-header:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0px;
  background-repeat: no-repeat;
  background-position: center center
}

.ti-profile-img img {
  width: 40px !important;
  margin: 0 15px 0 0 !important;
  height: 40px !important;
  padding: 0px !important;
  display: block;
  border-radius: 30px;
  min-width: initial !important;
  max-width: initial !important;
  object-fit: cover;
  object-position: top
}
.mimgfooter {
  width: 25px;
}
.ti-profile-details {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left
}

 .image-gallery {
   display: flex;
   flex-wrap: wrap;
   gap: 5px;
   text-align: center;
   justify-content: center;
 }

.image-gallery div {
  width: 300px;
  height: 300px;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.image-gallery div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.image-gallery div:hover img {
  transform: scale(1.25);
}
.ti-star {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(https://cdn.trustindex.io/assets/platform/Google/star/f.svg);
}

.ti-star:before, .ti-star:after {
  content: none !important
}

.ti-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}
@media only screen and (max-width: 660px) {
  .hidem {
    display: none;
  }
}


.ti-header .ti-name {
  font-size: 16px;
  overflow: visible;
  text-overflow: initial;
  white-space: normal;
  margin-top: 0px;
  margin-top: -3px;
  padding-right: 0px;
  margin-bottom: 5px;
  color: #000000 !important
}

.ti-header .ti-star {
  width: 20px;
  height: 20px
}

.ti-header .ti-star.ti-rating {
  color: #728EC2;
  font-weight: bold;
  display: inline-block;
  padding-right: 5px;
  position: relative;
  font-size: 16px;
  top: -2px
}

.ti-header .ti-profile-img img {
  width: 55px !important;
  height: 55px !important
}

.ti-header .ti-profile-details {
  padding-top: 6px
}

.ti-header .ti-stars {
  margin-top: 2px
}

.ti-review-item .inner, .ti-review-item .ti-inner {
  border-radius: 4px
}

.ti-stars {
  margin-top: 3px;
  white-space: nowrap
}

.ti-reviews-container-wrapper .inner > .ti-stars, .ti-reviews-container-wrapper .ti-inner > .ti-stars {
  margin-bottom: 6px;
  margin-top: 15px
}

.ti-star {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 1px
}

.star-lg .ti-star {
  width: 30px;
  height: 30px
}

.ti-footer {
  margin-top: 15px;
  padding-top: 0px;
  text-align: center;
  line-height: 1.45em
}

.ti-footer .ti-name {
  color: #000000;
  font-size: 16px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis
}

.ti-footer .ti-stars {
  margin-bottom: 3px;
  margin-top: 0px
}

.ti-footer .ti-profile-details {
  padding-top: 4px
}

.ti-footer .ti-inner {
  padding-bottom: 10px
}

.ti-footer-link-block {
  margin-top: 10px;
  color: #000000
}

.ti-footer-link-block a {
  font-size: 13.3px;
  padding-right: 10px;
  opacity: 0.7;
  text-decoration: underline !important;
  color: inherit !important
}

.ti-footer-link-block a:hover {
  opacity: 1
}

.ti-footer-copyright {
  font-size: 13px;
  margin-top: 5px;
  color: #777;
  text-align: center;
  line-height: 1.45em
}

.ti-footer-copyright a {
  font-size: 13px;
  color: #777
}

.ti-footer-copyright a:hover {
  color: #000
}

.ti-footer-copyright img {
  display: inline-block;
  width: 80px;
  height: auto;
  text-align: center;
  vertical-align: middle;
  margin-top: -6px
}

.ti-review-item > .inner, .ti-review-item > .ti-inner {
  border-style: solid !important;
  border-color: #efefef !important;
  background: #efefef !important;
  border-radius: 4px !important;
  padding: 15px !important;
  margin: 0px !important;
  display: block
}

.ti-d-none {
  display: none !important
}

.ti-widget[data-layout-id='9'][data-set-id='light-background'].ti-icon-size1 .ti-review-header:after {
  width: 25px;
  height: 25px
}

.ti-widget[data-layout-id='9'][data-set-id='light-background'].ti-icon-size1 .ti-widget-container .ti-name {
  padding-left: 30px
}

.head-3 {
  font-weight: bold;
  font-size: 24px;
  text-align: center !important;
  width: 100%;
  margin: 40px 0 !important;
}

#price {
  th {
    text-align: center;
  }
}

.price-bot {
  margin-bottom: 50px;
}

.price_table, .table-price, .wp-block-table {
  margin-bottom: 50px;
  width: 100%;

  td, th {
    border: 1px solid grey;
    padding: 5px;
  }

}

.ti-widget[data-layout-id='9'][data-set-id='light-background'].ti-icon-size2 .ti-review-header:after {
  width: 30px;
  height: 30px
}

.mb20 {
  margin-bottom: 20px;
}

.ti-review-item {
  color: black;
  margin-bottom: 10px;
}

.ti-review-content .ti-inner {
  max-height: 120px;
  overflow: auto;
  text-align: left;
}
.h-tel small, .h-tel2 small, [href] small {
  opacity: 1!important;
}
.ti-stars {
  text-align: left;
}

.h2 {
  margin: 20px 0;
}

iframe {
  width: 100%;
}

#contact {
  padding: 10px;
  //margin-bottom: -125px;
}

.site-footer a {
  color: white;
}

.site-footer {
  padding-top: 20px;
  background: #2a2a2a;
  color: white !important;
}
table td:last-child {
  width: 30%
}
h1 {
  word-break: break-word;
}
@media only screen and (max-width: 800px) {
  footer, .fot_text {
    text-align: center !important;
  }
  .mobile_center {
    text-align: center!important;
  }



}

.main-text, .main-text a {
  color: white !important;
  text-decoration: none;
  font-size: 20px;
}

.foot_logos .ib i {
  padding-top: 10px;
}

.foot_logos .ib {
  width: 40px;
  height: 40px;
  font-size: 20px;
  background: #616161;
  margin: 2px;
  text-align: center;
  border-radius: 50%;
}

.fot_text {
  color: #a6a6a6;
  font-size: 14px;
}

.sep {
  margin: 20px 0;
  border-top: 1px solid #404040;
}

.bank_imgs img {
  margin: 0 3px;
}

h1 {
  max-width: 1000px;
}
